
import favicon from "@/mixins/favicon"
import { routeToImpressum, routeToDatenschutz } from "@/helpers/routingEinBlick"
import { ThemeProvider } from "@/components/theme"

export default {
    name: "DefaultLayout",
    components: { ThemeProvider },
    mixins: [favicon],
    routes: {
        routeToDatenschutz,
        routeToImpressum,
    },
    computed: {
        theme() {
            return this.$businessProvider?.business?.theme?.theme
        },
    },
}
